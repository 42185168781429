<template>
  <div class="son">
    <div style="margin-bottom: 10px">
      <a-button
        type="danger"
        :disabled="!hasSelected"
        @click="onDelete(selectedRowKeys)"
      >
        删除
      </a-button>
    </div>
    <a-table
      ref="TableInfo"
      size="small"
      :bordered="true"
      :columns="columns"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      :dataSource="dataSource"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
    >
      <template slot="images" slot-scope="text, record">
        <div v-if="text.avatar" class="img-list">
          <el-image
            v-for="item in (text.avatar || '').split(',')"
            :key="item"
            style="width: 40px; height: 40px"
            :src="item | fullImgPath"
            fit="contain"
            :preview-src-list="(text.avatar || '').split(',')"
          />
        </div>
      </template>
      <template slot="content" slot-scope="text, record">
        <a-tooltip>
          <template slot="title"> {{ text.content }} </template>
          <div>
            {{
              text.content.length > 20
                ? text.content.slice(0, 25) + "..."
                : text.content
            }}
          </div>
        </a-tooltip>
      </template>
      <template slot="images2" slot-scope="text, record">
        <div v-if="record.media" class="img-list">
          <el-image
              v-for="item in JSON.parse(record.media )"
              :key="item"
              style="width: 40px; height: 40px"
              :src="item | fullImgPath"
              fit="contain"
              :preview-src-list="JSON.parse(record.media )"
          />
        </div>
      </template>
      <template slot="images1" slot-scope="text, record">
        <div v-if="text.avatar" class="img-list">
          <el-image
            v-for="item in (text.toAvatar || '').split(',')"
            :key="item"
            style="width: 40px; height: 40px"
            :src="item | fullImgPath"
            fit="contain"
            :preview-src-list="(text.toAvatar || '').split(',')"
          />
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <div
          v-hasAnyPermission="('advert:view', 'advert:edit', 'advert:delete')"
        >
          <a-button
            v-hasPermission="'advert:delete'"
            type="danger"
            @click="onDelete(record)"
          >
            删除
          </a-button>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { ListMixin } from "@/mixin/list-mixin";

export default {
  name: "expanTab",
  mixins: [ListMixin],
  data() {
    return {
      listGetUrl: "comment/listByAdmin",
      listMethod: "postJson",
      selectedRowKeys: [],
    };
  },
  computed: {
    hasSelected() {
      return this.selectedRowKeys.length > 0;
    },
    columns() {
      let { sortedInfo, filteredInfo } = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        // {
        //   title: "头像",
        //   width: 100,
        //   align: "center",
        //   scopedSlots: { customRender: "images" },
        // },
        {
          title: "发布用户名",
          width: 150,
          align: "center",
          dataIndex: "username",
        },
        {
          title: "认证公司名称",
          width: 170,
          align: "center",
          dataIndex: "company",
        },
        // {
        //   title: "@用户头像",
        //   width: 100,
        //   align: "center",
        //   scopedSlots: { customRender: "images" },
        // },
        {
          title: "@用户名",
          width: 150,
          align: "center",
          dataIndex: "toUsername",
        },
        {
          title: "@用户认证公司",
          width: 170,
          align: "center",
          dataIndex: "toCompany",
        },
        {
          title: "内容",
          align: "center",
          // dataIndex: "content",
          scopedSlots: { customRender: "content" },
        },
        {
          title: "图片",
          align: "center",
          width: 150,
          // dataIndex: "content",
          scopedSlots: { customRender: "images2" },
        },
        {
          title: "创建时间",
          width: 100,
          align: "center",
          dataIndex: "createTime",
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
        // {}
      ];
    },
  },
  methods: {
    showExpanTab(record) {
      this.queryParams = {
        pid: record.id,
        postId: record.postId,
        // type: 2,
      };
      this.search();
    },
    onDelete(record) {
      let msg = "您确定要删除【ID为" + record.id + "】的评论吗？";
      let data = [];
      if (Array.isArray(record)) {
        msg = "确定要删除所选评论吗？";
        data = record;
      } else {
        data = [record.id];
      }
      let that = this;
      this.$confirm({
        title: "删除确认",
        content: msg,
        centered: true,
        onOk() {
          that.$postJson("comment/delete", data).then(() => {
            that.$message.success("删除成功");
            that.search();
          });
        },
      });
    },
  },
};
</script>
